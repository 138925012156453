<template>
  <el-card>
    <div style="height:700px; overflow-x:hidden;overflow-y:scorll;padding:20px">
      <div v-if="isBatchEdit && needToPassage()">
        <h2>Passage</h2>
        <el-select
          :value="question.passage_id ? question.passage_id : null"
          @change="value => $emit('setQuestion', { passage_id: value })"
          filterable
          placeholder="為空就是不需要 Passage"
          style="width: 100%"
        >
          <el-option
            v-for="passage in passages"
            :key="passage.id"
            :label="
              `${passage.content.slice(0, 60)}${
                passage.content.length > 60 ? '...' : ''
              }`
            "
            :value="passage.id"
          />
        </el-select>
        <hr class="separate-line" />
      </div>
      <h2>Question</h2>
      <el-form-item>
        <template slot="label">
          題號
          <el-tooltip
            content="如果要修改題目順序，請至上一頁題目列表右上方點選「新增/刪除題目」開啟插入題目的調整。"
            placement="top"
            popper-class="tooltipColor"
          >
            <span class="action-icon">
              <i class="far fa-question-circle" />
            </span>
          </el-tooltip>
        </template>
        <el-input
          :disabled="isEditMode"
          placeholder="Order"
          :value="question.order"
          @input="value => $emit('setQuestion', { order: value })"
        />
      </el-form-item>
      <div>
        <Ckeditor
          :value="question.content || ''"
          @input="value => $emit('setQuestion', { content: value })"
        />
      </div>
      <hr class="separate-line" />
      <h2>Options</h2>
      <div>
        <div class="mb-2">
          <el-radio
            :value="question.type"
            label="default"
            @input="
              value => {
                setOptions(value);
                $emit('setQuestion', { type: value });
              }
            "
          >
            選擇題
          </el-radio>
          <el-radio
            :value="question.type"
            label="math"
            @input="
              value => {
                setOptions(value);
                $emit('setQuestion', { type: value });
              }
            "
          >
            數學填空題
          </el-radio>
        </div>
        <div>
          <div v-if="question.type === 'default'">
            <div
              v-for="(option, index) in question.options"
              :key="option.number"
              style="margin-bottom:20px"
              class="row"
            >
              <div class="col-sm-1 text-center">
                <h3 style="line-height:40px">
                  {{ option.letter }}
                </h3>
              </div>
              <div class="col-sm-11">
                <Ckeditor
                  :value="option.title || ''"
                  @input="value => changeOptions(index, value)"
                />
              </div>
            </div>
          </div>
          <el-alert
            title="答案輸入範例:"
            type="warning"
            show-icon
            style="margin-bottom: 10px"
            :closable="false"
          >
            <ul style="margin-bottom:0; line-height: 20px">
              <li>
                所有輸入都不可以有空格，並且符號都是英文狀態下輸入。
              </li>
              <li>如果這題沒有正確答案，請輸入 <kbd>*</kbd> 符號。</li>
              <li
                v-for="(item, index) in answerExamples[question.type]"
                :key="index"
                v-html="item"
              />
            </ul>
          </el-alert>
          <el-input
            placeholder="Correct Answer"
            style="margin-bottom:20px"
            :value="question.answer"
            @input="value => $emit('setQuestion', { answer: value })"
          >
            <template slot="prepend"><b>Correct Answer</b></template>
          </el-input>
        </div>
      </div>
      <div>
        <hr class="separate-line" />
        <h2>題目類型</h2>
        <div>
          <el-checkbox
            :value="question.is_coe"
            @change="value => $emit('setQuestion', { is_coe: value })"
            >Command of evidence</el-checkbox
          >
          <el-checkbox
            :value="question.is_wic"
            @change="value => $emit('setQuestion', { is_wic: value })"
            >Words in context</el-checkbox
          >
          <el-checkbox
            :value="question.is_eoi"
            @change="value => $emit('setQuestion', { is_eoi: value })"
            >Expression of ideas</el-checkbox
          >
          <el-checkbox
            :value="question.is_sec"
            @change="value => $emit('setQuestion', { is_sec: value })"
            >Standard english conventions</el-checkbox
          >
          <el-checkbox
            :value="question.is_hoa"
            @change="value => $emit('setQuestion', { is_hoa: value })"
            >Heart of algebra</el-checkbox
          >
          <el-checkbox
            :value="question.is_psada"
            @change="value => $emit('setQuestion', { is_psada: value })"
            >Problem solving and data analysis</el-checkbox
          >
          <el-checkbox
            :value="question.is_ptam"
            @change="value => $emit('setQuestion', { is_ptam: value })"
            >Passport to advanced math</el-checkbox
          >
        </div>
        <hr class="separate-line" />
      </div>
      <h2>Topic</h2>
      <div>
        <el-tabs v-model="tagActiveName" type="card">
          <el-tab-pane
            :key="tagType.key"
            v-for="tagType in examTagTypes"
            :name="tagType.key"
          >
            <template slot="label">
              {{ tagType.name }}
              <router-link
                :to="{
                  name: 'tags',
                  query: { tagType: tagType.key }
                }"
              >
                <i class="fas fa-edit action-icon" />
              </router-link>
            </template>
            <el-checkbox-group
              style="line-height: 0px"
              :value="question.tag_ids"
              @input="value => $emit('setQuestion', { tag_ids: value })"
              size="mini"
            >
              <el-checkbox-button
                v-for="tag in tags.filter(({ type }) => type === tagType.key)"
                :key="tag.id"
                :label="tag.id"
              >
                {{ helper.displayI18nText($i18n.locale, tag.name) }}
              </el-checkbox-button>
            </el-checkbox-group>
          </el-tab-pane>
        </el-tabs>
      </div>
      <hr class="separate-line" />
      <h2>Explanation</h2>
      <div>
        <Ckeditor
          :value="question.exp || ''"
          @input="value => $emit('setQuestion', { exp: value })"
        />
      </div>
    </div>
  </el-card>
</template>

<script>
import { helper } from "@ivy-way/material";
import Ckeditor from "@/components/ckeditor/index.vue";

export default {
  components: {
    Ckeditor
  },
  props: {
    sectionName: {
      type: String,
      default: ""
    },
    question: {
      type: Object,
      default: () => ({})
    },
    tags: {
      type: Array,
      default: () => []
    },
    passages: {
      type: Array,
      default: () => []
    },
    isBatchEdit: {
      type: Boolean,
      default: false
    },
    isEditMode: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    helper() {
      return helper;
    },
    answerExamples() {
      return {
        default: [
          "單選題中有一個正確答案：<b>A</b>。",
          "單選題中有多個正確答案，用 <kbd>,</kbd> 隔開：<b>A,B</b>。"
        ],
        math: [
          "有一個正確答案：<b>1/2</b>。",
          "有多個正確答案，用<b>,</b> 隔開：<b>1/2,0.5</b>。",
          "如果答案是區間範圍，用 <kbd>&lt;</kbd> 、 <kbd>&gt;</kbd> 、<kbd>&gt;=</kbd>、 <kbd>&lt;=</kbd>：<b>1&lt;X&lt;2</b> 或 <b>1&lt;=X&lt;=2</b>。",
          "如果答案是區間範圍，並需使用大於等於或小於等於，請記得 <kbd>=</kbd> 要放在 <kbd>&lt;</kbd> 、 <kbd>&gt;</kbd> 後方。"
        ]
      };
    },
    examTagTypes() {
      return [
        { name: "Gammar", key: "question_grammar" },
        { name: "Math", key: "question_math" },
        { name: "Reading", key: "question_reading" },
        { name: "Science", key: "question_science" }
      ];
    }
  },
  data() {
    return {
      options: {
        default: [
          { letter: "A", number: 1, title: "" },
          { letter: "B", number: 2, title: "" },
          { letter: "C", number: 3, title: "" },
          { letter: "D", number: 4, title: "" }
        ]
      },
      tagActiveName: "question_grammar"
    };
  },
  watch: {
    "question.order"() {
      this.$emit("setQuestion", { options: this.getOptions(this.question) });
    }
  },
  async created() {
    if (this.question) {
      this.$emit("setQuestion", { options: this.getOptions(this.question) });
    }
  },
  methods: {
    changeOptions(targetIndex, value) {
      const options = this.question.options.map((option, index) => {
        if (targetIndex === index) {
          return { ...option, title: value };
        }
        return option;
      });
      this.$emit("setQuestion", { options });
    },
    getOptions(question) {
      let options = question.options;
      if (options.length > 0) {
        return options;
      } else {
        return this.options.default;
      }
    },
    setOptions(type) {
      let oddEvenType = "odd";
      if (this.question.order % 2 === 0) {
        oddEvenType = "even";
      }
      let options = [];
      if (type === "math") {
        for (let i = 0; i < 5; i++) {
          if (this.question.options[i] === undefined) {
            options[i] = this.actOptions[oddEvenType][type][i];
          } else {
            options[i] = this.question.options[i];
          }
        }
      }
      if (type === "default") {
        for (let i = 0; i < 4; i++) {
          options[i] = this.question.options[i];
        }
      }
      this.$emit("setQuestion", { options });
    },
    needToPassage() {
      return ["english", "reading", "writing", "science", "essay"].includes(
        this.sectionName
      );
    }
  }
};
</script>
<style scoped>
.ivy-question >>> p {
  margin-bottom: 0;
}
</style>
