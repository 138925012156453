<template>
  <SatQuestionViewer
    :disableDelete="disableDelete"
    :disabledAction="disabledAction"
    :displayCorrectAnswer="displayCorrectAnswer"
    :displayOptions="displayOptions"
    :question="question"
    :viewPath="
      $router.resolve({ name: 'Question', query: { questionId: question.id } }).href
    "
    :editPath="
      $router.resolve({
        name: 'EditQuestion',
        query: { examId, questionId: question.id }
      }).href
    "
    :passagePath="
      $router.resolve({
        name: 'Passage',
        query: { examId, questionId: question.sat_passage_id }
      }).href
    "
    @onDeleteQuestion="() => deleteQuestion(question.id)"
  />
</template>

<script>
import satApi from "@/apis/sat.js";

export default {
  props: {
    examId: {
      type: String,
      default: ""
    },
    sectionId: {
      type: String,
      default: ""
    },
    disableDelete: {
      type: Boolean,
      default: true
    },
    disabledAction: {
      type: Boolean,
      default: false
    },
    displayOptions: {
      type: Boolean,
      default: false
    },
    displayCorrectAnswer: {
      type: Boolean,
      default: true
    },
    question: {
      type: Object,
      default: () => ({
        id: null,
        order: null,
        content: "",
        options: [],
        tags: [],
        exp: ""
      })
    }
  },
  methods: {
    async deleteQuestion(questionId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await satApi.deleteQuestion(questionId);
        this.$message.success(this.$t("message.delete_success"));
        this.$emit("reload");
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>
